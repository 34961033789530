/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';

function MaisonMarieFAQ({ noHeader, lang }) {
  const [questions, setQuestions] = useState([]);
  const toggleAnswer = (id, e) => {
    e.preventDefault();
    const cloneQuestions = [...questions];
    cloneQuestions.forEach((faq) => {
      if (faq.id === id) {
        faq.isOpen = !faq.isOpen;
      }
    });
    setQuestions(cloneQuestions);
  };

  const faq = {
    en: [
      {
        id: 1,
        title: 'How do I join?',
        answer: 'It’s easy! Your email will contain a login link. Click through to access your membership.',
      },
      {
        id: 2,
        title: 'Where can I track progress?',
        answer: 'You can track your progress by logging into your MSP loyalty program account and going to your benefits page. When you level up to a new tier, you will see all the new perks on your benefits page!',
      },
      {
        id: 3,
        title: 'How is my membership status determined?',
        answer: 'Your MSP status is determined by how much you spend each calendar year. You can make these purchases at shop.mariesaintpierre.com or at one of our retail locations. Scroll up to see the minimum annual spend for each tier in our benefits chart.',
      },
      {
        id: 4,
        title: 'How do I earn points and level up tiers?',
        answer: 'The more you shop, the closer you get to leveling up to a new tier and accessing more perks.',
      },
      {
        id: 5,
        title: 'Do tiers/points expire or reset?',
        answer: 'Points will expire after 1 year of inactivity.',
      },
      {
        id: 6,
        title: 'If I return my order, will my points remain?',
        answer: 'When you return an item, the points will be deducted from your yearly spending total.',
      },
    ],
  };

  useEffect(() => {
    setQuestions(faq.en);
  }, []);

  return (
    <div className="asset-faq">
      {!noHeader
      && <h2 className="digital-asset__subheader -center">FAQ</h2>}
      {questions.map((faq) => (
        <div
          key={faq.id}
          className="asset-faq__item">
          {faq.isOpen
            ? (
              <a
                className="asset-faq__toggle"
                href="#"
                onClick={(e) => toggleAnswer(faq.id, e)}>
                -
              </a>
            )
            : (
              <a
                className="asset-faq__toggle"
                href="#"
                onClick={(e) => toggleAnswer(faq.id, e)}>
                +
              </a>
            )}
          <div
            className="asset-faq__item--question"
            onClick={(e) => toggleAnswer(faq.id, e)}>
            {faq.title}
          </div>
          <div
            className={faq.isOpen ? 'asset-faq__item--answer -open' : 'asset-faq__item--answer'}
            dangerouslySetInnerHTML={{ __html: faq.answer }}>
          </div>
        </div>
      ))}
      
    </div>
  );
}

export default MaisonMarieFAQ;
