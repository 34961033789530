import React, { useState, useEffect } from 'react';

const MaisonMarieFlexContent = ({ lang }) => {
  // const [transferCode, setTransferCode] = useState(null);

  const scrollToTop = (e) => {
    e.preventDefault();
    // const scrollToElement = document.getElementById(element);
    // scrollToElement.scrollIntoView({ behavior: 'smooth' });
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    // on mount
  }, []);

  return (
    <div className="flex-content -no-margin">
      <div className="flex-content--copy">
        <div className="flex-content__inner">
          <p>Members can enjoy:</p>
          <ul>
            <li className="flex-content__image-bullet">
              <img className="-bullet" src="https://assets.heyethos.com/maisonmarie/bullet/bullet-1.png" alt="" />
              Exclusive & Invitation-Only Events
            </li>
            <li className="flex-content__image-bullet">
              <img className="-bullet" src="https://assets.heyethos.com/maisonmarie/bullet/bullet-2.png" alt="" />
              Exclusive Discounts
            </li>
            <li className="flex-content__image-bullet">
              <img className="-bullet" src="https://assets.heyethos.com/maisonmarie/bullet/bullet-3.png" alt="" />
              MSP Anniversary (TBC)
            </li>
            <li className="flex-content__image-bullet">
              <img className="-bullet" src="https://assets.heyethos.com/maisonmarie/bullet/bullet-4.png" alt="" />
              Early Access to Collections
            </li>
            <li className="flex-content__image-bullet">
              <img className="-bullet" src="https://assets.heyethos.com/maisonmarie/bullet/bullet-5.png" alt="" />
              Referral Program
            </li>
            <li className="flex-content__image-bullet">
              <img className="-bullet" src="https://assets.heyethos.com/maisonmarie/bullet/bullet-6.png" alt="" />
              Access to exclusive colors & styles
            </li>
            <li className="flex-content__image-bullet">
              <img className="-bullet" src="https://assets.heyethos.com/maisonmarie/bullet/bullet-1.png" alt="" />
              Contests
            </li>
            <li className="flex-content__image-bullet">
              <img className="-bullet" src="https://assets.heyethos.com/maisonmarie/bullet/bullet-2.png" alt="" />
              Early Access to Sales
            </li>
          </ul>
        </div>
      </div>
      <div
        className="flex-content--image"
        style={{
          backgroundImage: 'url(' + process.env.ALTERNATE_BACKGROUND + ')',
        }}>
      </div>
    </div>
  );
};
export default MaisonMarieFlexContent;
